$main-font: "rubik-regular";
$main-font-light: "rubik-light";
$main-font-medium: "rubik-medium";
$main-font-bold: "rubik-bold";

$color_dark: #707070;
$color_light: #FAF9F6;
$feature_color: #57DE86;
$feature_color-light: #E2FDEB;
$bg_header: #E5E5E5;
$white: #FFF;
$mobile_width: 900px;

$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-bold: 700;

$row-min-width: 1024px;

$v-margin: 85px;
$mobile-v-margin: 32px;

$first_col_width: 340px;

