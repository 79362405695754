html, h1, h2 {
  color: #707070;
  scroll-behavior: smooth;
  font-family: rubik-regular;
  font-size: 15px;
  font-weight: 400 !important;
}

a {
  text-underline: none;
  color: #707070;
  text-decoration-line: none;
}

a:hover {
  color: #707070;
}

.h2 {
  margin: 0;
  font-size: 2.4rem;
}

.d-flex {
  display: flex;
}

.text-strong {
  font-family: rubik-bold;
  font-weight: 700 !important;
}

.nowrap {
  white-space: nowrap;
}

p {
  margin: 0;
}

.main-body {
  max-width: 1280px;
  background-color: #faf9f6;
  justify-content: center;
  margin: auto;
  padding: 0 53px;
  display: flex;
}

@media only screen and (hover: none) and (pointer: coarse) {
  .main-body {
    overscroll-behavior-y: none;
    padding: 0;
  }
}

.color-feature {
  color: #57de86;
  font-family: rubik-medium;
}

.color-feature:hover {
  color: #57de86;
}

.font-medium {
  font-family: rubik-medium;
  font-weight: 500 !important;
}

.container {
  width: 100%;
}

.header {
  height: 260px;
  min-width: 1024px;
  background-color: #57de86;
  justify-content: space-between;
  display: flex;
}

@media only screen and (hover: none) and (max-aspect-ratio: 1) {
  .header {
    min-width: 0;
  }
}

.header-col0 {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.header-col0-text {
  color: #faf9f6;
  margin-left: 85px;
  font-family: rubik-light;
  font-size: 3.8rem;
  display: block;
  font-weight: 300 !important;
}

@media only screen and (hover: none) and (max-aspect-ratio: 1) {
  .header-col0-text {
    margin-left: 32px;
    font-size: 3.13rem;
  }
}

.header-col0-text-sub {
  letter-spacing: .13rem;
  color: #e2fdeb;
  margin-top: .66rem;
  margin-left: 85px;
  font-family: rubik-medium;
  font-size: 1.2rem;
  display: block;
  font-weight: 500 !important;
}

@media only screen and (hover: none) and (max-aspect-ratio: 1) {
  .header-col0-text-sub {
    margin-left: 32px;
    font-size: .9rem;
  }
}

.header-col1 {
  width: 20%;
  background-color: #e5e5e5;
}

@media only screen and (hover: none) and (max-aspect-ratio: 1) {
  .header-col1 {
    display: none;
  }
}

.row {
  min-width: 1024px;
  flex-direction: row;
  padding: 0;
  font-size: 1rem;
  line-height: 2.2rem;
  display: flex;
}

@media only screen and (hover: none) and (max-aspect-ratio: 1) {
  .row {
    min-width: 0;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.row-title {
  box-sizing: border-box;
  min-width: 340px;
  padding: 100px 0 100px 85px;
}

@media only screen and (hover: none) and (max-aspect-ratio: 1) {
  .row-title {
    min-width: 0;
    padding: 80px 0 80px 32px;
  }
}

.row-title-index {
  font-family: rubik-medium;
  font-size: 1rem;
  font-weight: 500 !important;
}

.row-title-index:before {
  content: "";
  width: 20px;
  border-top: 2px solid #57de86;
  margin: 5px 0 10px;
  display: block;
}

.row-text {
  width: 100%;
  text-align: left;
  padding: 100px 85px 85px 100px;
}

@media only screen and (hover: none) and (max-aspect-ratio: 1) {
  .row-text {
    min-width: 0;
    box-sizing: border-box;
    padding: 0 32px;
  }
}

.row .h2 {
  padding-bottom: 1rem;
}

.row-menu {
  justify-content: flex-start;
  padding: 60px 85px 100px 0;
  font-size: .9rem;
  display: flex;
}

@media only screen and (hover: none) and (max-aspect-ratio: 1) {
  .row-menu {
    display: none;
  }
}

.row-menu-contact {
  min-width: 340px;
  box-sizing: border-box;
  padding-left: 85px;
  font-family: rubik-medium;
  font-size: 1.2rem;
  font-weight: 500 !important;
}

.row-menu-contact :nth-child(2) {
  font-size: .9rem;
  line-height: 1rem;
}

.row-menu-nav {
  width: calc(100% - 340px);
  justify-content: space-between;
  padding-left: 100px;
  font-family: rubik-medium;
  font-size: .93rem;
  display: flex;
  font-weight: 500 !important;
}

.row-menu-nav a {
  display: block;
}

.row-menu-nav a:after {
  content: "";
  width: 20px;
  width: 0;
  opacity: 0;
  border-top: 2px solid #57de86;
  margin: 5px 0 10px;
  display: block;
}

.row-menu-nav a:hover:after {
  opacity: 1;
  width: 20px;
  transition: all .2s ease-in-out;
}

.about-row {
  align-content: flex-start;
  display: flex;
}

@media only screen and (hover: none) and (max-aspect-ratio: 1) {
  .about-row {
    flex-direction: column;
  }
}

.about-row-image {
  justify-content: center;
  padding: 100px 0 85px 15px;
}

@media only screen and (hover: none) and (max-aspect-ratio: 1) {
  .about-row-image {
    padding: 32px;
  }
}

.about-row-text {
  min-width: 200px;
}

.about-row img {
  width: 300px;
  height: 424px;
  pointer-events: none;
}

@media only screen and (hover: none) and (max-aspect-ratio: 1) {
  .about .row-title {
    padding-bottom: 0;
  }
}

.contact {
  color: #faf9f6;
}

@media only screen and (hover: none) and (max-aspect-ratio: 1) {
  .contact {
    display: none;
  }
}

.contact-container {
  position: relative;
  top: -32px;
  right: 36px;
}

.contact .row-title {
  background-color: #57de86;
}

.contact .h2 {
  color: currentColor;
}

.contact .row-text {
  color: #707070;
  background-color: #e5e5e5;
  font-family: rubik-medium;
  font-size: 1rem;
  position: relative;
  font-weight: 500 !important;
}

.contact .row-title-index {
  color: #707070;
}

.contact .row-title-index:before {
  border-color: #faf9f6;
}

.contact-name {
  font-family: rubik-bold;
  font-weight: 700 !important;
}

@media only screen and (hover: none) and (max-aspect-ratio: 1) {
  .contact-name {
    font-family: rubik-medium;
    font-size: 1.8rem;
    font-weight: 500 !important;
  }
}

.contact-group {
  padding-top: 32px;
}

.contact-detail {
  align-items: center;
  font-family: rubik-medium;
  display: flex;
  font-weight: 500 !important;
}

.contact-detail-icon {
  width: 36px;
  height: 32px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

@media only screen and (hover: none) and (max-aspect-ratio: 1) {
  .contact-detail-icon {
    width: 40px;
  }
}

.contact-detail-icon img {
  width: 18px;
  height: 24px;
  display: inline-block;
}

.contact-detail-icon-mail img {
  width: 25px;
}

.map-container {
  height: 300px;
  position: relative;
}

.map-container .row-text .row-title {
  padding-bottom: 0;
}

.map {
  min-width: 1024px;
  width: 100%;
  height: 280px;
  box-sizing: border-box;
  border: 0;
  padding: 0 85px;
  position: absolute;
}

.foot {
  font-size: .8rem;
  font-family: rubik-regular !important;
}

.foot .row-text {
  text-align: right;
  font-size: .8rem;
}

.foot .row-text, .foot .row-title {
  padding-top: 0;
  padding-bottom: 32px;
}

.foot-mobile {
  width: 100%;
  height: 64px;
  box-sizing: border-box;
  background-color: #57de86;
  justify-content: flex-end;
  align-items: center;
  margin-top: 32px;
  padding-right: 32px;
  font-family: rubik-medium;
  display: none;
  font-weight: 500 !important;
}

@media only screen and (hover: none) and (max-aspect-ratio: 1) {
  .foot-mobile {
    display: flex;
  }
}

.foot-mobile a {
  align-items: center;
  display: flex;
}

.foot-mobile-link {
  color: #faf9f6;
  padding-right: 10px;
}

.contact-mobile {
  padding-top: 32px;
  display: none;
}

@media only screen and (hover: none) and (max-aspect-ratio: 1) {
  .contact-mobile {
    align-items: flex-start;
    display: flex;
  }
}

.twoj-psycholog-mobile {
  width: 100%;
  padding-top: 32px;
}

/*# sourceMappingURL=index.d7cbfd78.css.map */
