@import "constants";
@import "font-mixins";
@import "mixins";

html, h1, h2 {
  font-size: 15px;
  font-family: $main-font;
  font-weight: $weight-regular !important;
  color: $color_dark;
  scroll-behavior: smooth;
}

a {
  text-underline: none;
  text-decoration-line: none;
  color: $color_dark;

  &:hover {
    color: $color_dark;
  }

  &:visited {
  }
}

.h1 {
}

.h2 {
  font-size: 2.4rem;
  margin: 0;
  //font-weight: $weight-light;
}

.d-flex {
  display: flex;
}

.text-strong {
  @include font-bold();
}

.nowrap {
  white-space: nowrap;
}

p {
  margin: 0;
}

.main-body {
  margin: auto;
  background-color: $color_light;
  display: flex;
  justify-content: center;
  padding: 0 53px;
  @include touch_device {
    padding: 0;
    overscroll-behavior-y: none;
  }
  max-width: 1280px;
}

.color-feature {
  font-family: $main-font-medium;
  color: $feature_color;
  &:hover {
    color: $feature_color;

  }
}

.font-medium{
  @include font-medium();
}

.container {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  background-color: $feature_color;
  height: 260px;
  min-width: $row_min-width;
  @include mobile {
    min-width: 0;
  }

  &-col0 {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-text {
      font-size: 3.8rem;
      @include font-light();
      margin-left: $v-margin;
      @include mobile {
        margin-left: $mobile-v-margin;
        font-size: 3.13rem;
      }
      display: block;
      color: $color_light;

      &-sub {
        font-size: 1.2rem;
        @include font-medium();
        margin-left: $v-margin;
        @include mobile {
          margin-left: $mobile-v-margin;
          font-size: 0.9rem;
        }
        margin-top: 0.66rem;
        display: block;
        letter-spacing: 0.13rem;
        color: $feature_color-light;
      }
    }
  }

  &-col1 {
    width: 20%;
    background-color: $bg_header;
    @include mobile {
      display: none;
    }
  }
}


.row {
  padding: 0;
  line-height: 2.2rem;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  min-width: $row_min-width;
  @include mobile {
    flex-direction: column;
    justify-content: flex-start;
    min-width: 0;
  }

  &-title {
    padding: 100px 0 100px $v-margin;
    box-sizing: border-box;
    min-width: $first_col_width;
    @include mobile {
      padding: 80px 0 80px $mobile-v-margin;
      min-width: 0;
    }

    &-index {
      font-size: 1rem;
      @include font-medium();

      &::before {
        @include line();
      }
    }
  }

  &-text {
    width: 100%;
    padding: 100px $v-margin $v-margin 100px;
    @include mobile {
      padding: 0 $mobile-v-margin 0 $mobile-v-margin;
      min-width: 0;
      box-sizing: border-box;
    }
    text-align: left;
  }

  & .h2 {
    padding-bottom: 1rem;
  }

  &-menu {
    @include mobile {
      display: none;
    }
    padding: 60px $v-margin 100px 0;
    display: flex;
    justify-content: flex-start;
    font-size: 0.9rem;

    &-contact {
      padding-left: $v-margin;
      min-width: $first_col_width;
      font-size: 1.2rem;
      box-sizing: border-box;
      @include font-medium();

      & :nth-child(2) {
        font-size: 0.9rem;
        line-height: 1rem;
      }

    }

    &-nav {
      display: flex;
      padding-left: 100px;
      justify-content: space-between;
      width: calc(100% - 340px);
      font-size: 0.93rem;
      @include font-medium();

      a {
        display: block;

        &::after {
          @include line();
          width: 0;
          opacity: 0;
        }

        &:hover::after {
          opacity: 1;
          width: 20px;
          transition: all .2s ease-in-out;
        }
      }
    }

    @media all and (max-width: $mobile_width) {

      //visibility: hidden;
      //height: 0;
      //padding: 0;
    }
  }
}

.about {
  &-row {
    display: flex;
    align-content: flex-start;
    @include mobile {
      flex-direction: column;
    }

    &-image {
      padding: 100px 0 $v-margin 15px;
      justify-content: center;
      @include mobile {
        padding: $mobile-v-margin;
      }
    }

    &-text {
      min-width: 200px;
    }

    & img {
      width: 300px;
      height: 424px;
      pointer-events: none;
    }
  }

  @include mobile {
    & .row-title {
      padding-bottom: 0;
    }
  }
}



.contact {
  @include mobile {
    display: none;
  }
  color: $color_light;

  &-container {
    top: -32px;
    right: 36px;
    position: relative;
  }



  & .row-title {
    background-color: $feature_color;
  }

  & .h2 {
    color: currentColor;
  }

  & .row-text {
    color: $color_dark;
    background-color: $bg_header;
    font-size: 1rem;
    position: relative;
    @include font-medium();
  }

  & .row-title-index {
    color: $color_dark;
  }

  & .row-title-index::before {
    border-color: $color_light;
  }

  &-name {
    @include font-bold();
    @include mobile {
      @include font-medium();
      font-size: 1.8rem;
    }
  }

  &-group {
    padding-top: 32px;
  }

  &-detail {
    display: flex;
    align-items: center;
    @include font-medium();

    &-icon {
      width: 36px;
      @include mobile {
        width: 40px;
      }
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        display: inline-block;
        width: 18px;
        height: 24px;
      }

      &-mail {
        img {
          width: 25px;
        }
      }
    }
  }

}

.map-container {
  position: relative;
  height: 300px;

  & .row-text .row-title {
    padding-bottom: 0px;
  }
}

.map {
  min-width: $row_min-width;
  width: 100%;
  height: 280px;
  border: 0;
  padding: 0 $v-margin;
  box-sizing: border-box;
  position: absolute;
}

.foot {
  font-size: 0.8rem;
  font-family: $main-font !important;

  & .row-text {
    text-align: right;
    font-size: 0.8rem;
  }

  & .row-text, & .row-title {
    padding-top: 0;
    padding-bottom: 32px;
  }
}

.foot-mobile {
  display: none;
  @include mobile {
    display: flex;
  }
  margin-top: $mobile-v-margin;
  padding-right: $mobile-v-margin;
  width: 100%;
  height: 64px;
  background-color: $feature_color;
  @include font-medium();
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;

  & a {
    display: flex;
    align-items: center;
  }

  &-link {
    color: $color_light;
    padding-right: 10px;
  }
}

.contact-mobile {
  display: none;
  padding-top: $mobile-v-margin;
  @include mobile {
    display: flex;
    align-items: flex-start;
  }
}



.twoj-psycholog-mobile {
  padding-top: 32px;
  width: 100%;
}



