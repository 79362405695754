@import "constants";

@mixin font-light() {
  font-family: $main-font-light;
  font-weight: $weight-light !important;
}

@mixin font-medium() {
  font-family: $main-font-medium;
  font-weight: $weight-medium !important;
}

@mixin font-bold() {
  font-family: $main-font-bold;
  font-weight: $weight-bold !important;
}
