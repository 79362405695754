@import "constants";


@mixin mobile {
  @media only screen and (hover: none) and (max-aspect-ratio: 1/1) {
    @content;
  }
}

@mixin touch_device {
  @media only screen and (hover: none) and (pointer: coarse) {
    @content;
  }
}


@mixin line() {
  display: block;
  border-top: 2px solid $feature_color;
  content: "";
  width: 20px;
  margin: 5px 0 10px 0;
}
